import { HttpLink } from "apollo-link-http";
import { api_base, medical_base } from "Utils/requests";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

export const httpLink = createUploadLink({
    uri: `${api_base}/graphql/`,
    credentials: "include",
    headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": `${api_base}`,
        // "apollo-require-preflight": "true",
    },
});
export const medicalHttpLink = new HttpLink({
    uri: `${medical_base}/graphql/`,
    credentials: "include",
});
export const authHttpLink = new HttpLink({
    uri: `${api_base}/graphql/`,
    credentials: "include",
});
