import {
    CampaignType,
    CompanyAddressType,
    CompanyType,
    ContactPersonType,
    CustomerAddressType,
    CustomerType,
    DocumentCategoryType,
    DocumentType,
    DocumentVersionType,
    FitnessTestDeviceType,
    FitnesstestsFitnessTestDeviceDeviceTypeChoices,
    Functionality,
    HealthcareTeamType,
    InsuranceCompanyType,
    InsurancesInsuranceInsuranceTypeChoices,
    InsuranceType,
    InvoiceInterfaceType,
    LaboratorySupplierType,
    MeType,
    NodeGroupType,
    NodeType,
    OrganizationsContactPersonContactTypeChoices,
    OrganizationsCustomerEntrepreneurInsuranceTypeChoices,
    PartnerType,
    PaymentcommitmentsPaymentCommitmentKelaActionCategoryChoices,
    PaymentcommitmentsPaymentCommitmentReimbursementClassChoices,
    PaymentCommitmentType,
    ProfessionalGroupType,
    ProfileType,
    QuestionsQuestionAnswerTypeChoices,
    QuestionType,
    SalescatalogsSalesCatalogStateChoices,
    SalesCatalogType,
    SiteType,
    TasksTaskRecurrenceIntervalChoices,
    TasksTaskSchedulingChoices,
    TasksTaskStateChoices,
    TaskTopicType,
    TaskType,
    UsersUserLanguageChoices,
    UserType,
    ViewsViewViewTypeChoices,
    ViewType,
    ViewViewTypeChoices,
} from "types/graphql";
import { DEFAULT_COUNTRY, ID_EMPTY } from "Utils/constants";

export const MeEmpty: MeType = {
    id: ID_EMPTY,
    uuid: ID_EMPTY,
    email: "",
    firstName: "",
    lastLogin: null,
    lastName: "",
    primarySite: SiteType.MainBackend,
    companyId: ID_EMPTY,
    customerId: ID_EMPTY,
};

export const UserEmpty: UserType = {
    id: ID_EMPTY,
    firstName: "",
    homeTown: "",
    country: DEFAULT_COUNTRY,
    email: "",
    isActive: false,
    isHealthcareWorker: false,
    isStaff: false,
    isSupplier: false,
    nationalIdKnown: false,
    password: "",
    phoneNumber: "",
    phoneNumberJob: "",
    address: "",
    postCode: "",
    city: "",
    language: UsersUserLanguageChoices.Fi,
    dateJoined: "",
    laboratorytestSet: [],
    lastName: "",
    rolesCompany: [],
    supervisor: false,
    supervisorSubstitute: null,
    supervisorSubstituteEndDate: null,
    supervisorSubstituteStartDate: null,
    surveyAnswers: [],
    titletemplates: [],
    uuid: null,
    idAcute: "",
    views: [],
    campaignSurveys: [],
    createdAt: "",
    createdCampaigns: [],
    rolesCustomer: [],
    rolesCorporate: [],
    documentversionSet: [],
    invoicepurchaseSet: [],
    invoicepurchasecommentSet: [],
    healthcareteams: [],
    acknowledgments: [],
    employeeAcknowledgments: [],
    tasksOwned: [],
    tasksPatient: [],
    documentSet: [],
    employeeGroup: null,
    benefitsRestrictionReason: null,
    benefitsRestrictedUntil: null,
    hasExposure: false,
    jobTitle: "",
    contactPersons: [],
    responsibleDoctorCustomers: [],
    responsibleNurseCustomers: [],
    insurances: [],
    directSubordinates: [],
    employmentInformation: [],
    salescontractversionSet: [],
    healthChecks: [],
    healthCheckInformation: null,
    paymentcommitmentSet: [],
    usersynclogSet: [],
    acceptedSickLeaves: [],
    sickLeaves: [],
    substituteSupervisors: [],
};

export const CompanyEmpty: CompanyType = {
    id: ID_EMPTY,
    name: "",
    businessId: "",
    phone: "",
    isPrimary: false,
    addresses: [],
    companyRoles: [],
    usersCompany: [],
    customerSet: [],
    profileSet: [],
    campaigns: [],
    documentcategorySet: [],
    invoicepurchaseSet: [],
    healthcareteams: [],
    createdAt: "",
};

export const CustomerEmpty: CustomerType = {
    id: ID_EMPTY,
    company: CompanyEmpty,
    name: "",
    marketingName: "",
    phone: "",
    businessId: "",
    idAcute: "",
    eInvoicingAddress: "",
    eInvoicingOperator: "",
    invoicingAddressStreetAddress: "",
    invoicingAddressPostCode: "",
    invoicingAddressCity: "",
    acknowledgments: [],
    attachmentAddressStreetAddress: "",
    attachmentAddressPostCode: "",
    attachmentAddressCity: "",
    attachmentAddressEmail: "",
    addresses: [],
    contracts: [],
    customerRoles: [],
    profileSet: [],
    usersCustomer: [],
    documentSet: [],
    invoicesaleSet: [],
    taskSet: [],
    contactPersons: [],
    descriptionOfOperations: "",
    descriptionOfOrganization: "",
    descriptionOfStaff: "",
    occupationalHealthNeeds: "",
    occupationalSafetyNeeds: "",
    internalNotes: "",
    iban: "",
    entrepreneurInsuranceType:
        OrganizationsCustomerEntrepreneurInsuranceTypeChoices.Yel,
    taxFreeBenefitsInstructionsGiven: false,
    insurances: [],
    createdAt: "",
    departments: [],
    employeegroupSet: [],
};

export const CustomerAddressEmpty: CustomerAddressType = {
    id: ID_EMPTY,
    customer: CustomerEmpty,
    isPrimary: false,
    phone: "",
    address: "",
    postCode: "",
    city: "",
    country: "",
    description: "",
    createdAt: "",
    useremploymentinformationSet: [],
};

export const CompanyAddressEmpty: CompanyAddressType = {
    id: ID_EMPTY,
    company: CompanyEmpty,
    isPrimary: false,
    phone: "",
    address: "",
    postCode: "",
    city: "",
    country: "",
    description: "",
    createdAt: "",
    useremploymentinformationSet: [],
};

export const ProfileEmpty: ProfileType = {
    id: ID_EMPTY,
    name: "",
    customer: undefined,
    permissions: [],
    corporate: false,
    anyCompany: false,
    anyCustomer: false,
    rolecompanySet: [],
    rolecorporateSet: [],
    rolecustomerSet: [],
    createdAt: "",
};

export const FunctionalityEmpty: Functionality = {
    companies: [],
    customers: [],
    isHealthcareWorker: false,
    isSupplier: false,
    language: "fi",
    perms: [],
    primaryUi: ViewViewTypeChoices.Customeradmin,
    showCorporateAdminViews: false,
    showCustomerAdminViews: false,
    showHealthcareWorkerViews: false,
    showRegionalAdminViews: false,
    showSupplierViews: false,
};

export const LaboratorySupplierEmpty: LaboratorySupplierType = {
    id: ID_EMPTY,
    name: "",
    city: "",
    address: "",
    postCode: "",
    phoneNumber: "",
    discountPercent: "",
    pricePerVisit: "",
    createdAt: "",
    childLaboratories: [],
    code: {
        abbreviation: "",
        address: "",
        beginDate: "",
        city: "",
        expirationDate: "",
        hierarchyLevel: "",
        parentId: "",
        phoneNumber: "",
        postCode: "",
        codeId: ID_EMPTY,
        id: ID_EMPTY,
        laboratorysupplierSet: [],
        businessId: "",
    },
    supplierpackagepriceSet: [],
    suppliertestpriceSet: [],
    updatedAt: "",
};

export const CampaignEmpty: CampaignType = {
    id: ID_EMPTY,
    name: "",
    documentTemplates: [],
    surveys: [],
    createdAt: "",
};

export const DocumentCategoryEmpty: DocumentCategoryType = {
    id: ID_EMPTY,
    company: CompanyEmpty,
    title: "",
    description: "",
    hideOldVersions: false,
    showForCustomers: false,
    showForCustomerUsers: false,
    documentSet: [],
    documentcategorySet: [],
    taskSet: [],
    createdAt: "",
};

export const DocumentEmpty: DocumentType = {
    id: ID_EMPTY,
    customer: CustomerEmpty,
    documentCategory: DocumentCategoryEmpty,
    title: "",
    documentversionSet: [],
    taskSet: [],
    createdAt: "",
};

export const DocumentVersionEmpty: DocumentVersionType = {
    id: ID_EMPTY,
    document: DocumentEmpty,
    createdBy: UserEmpty,
    versionNumber: 0,
    file: "",
    fileThumbnail: "",
    createdAt: "",
};

export const InvoiceInterfaceEmpty: InvoiceInterfaceType = {
    id: ID_EMPTY,
    company: CompanyEmpty,
    apiKey: "",
    apiUser: "",
    createdAt: "",
};

export const ViewEmpty: ViewType = {
    id: ID_EMPTY,
    title: "",
    viewType: ViewsViewViewTypeChoices.Customeradmin,
    permissions: [],
    users: [],
    createdAt: "",
};

export const TaskEmpty: TaskType = {
    id: ID_EMPTY,
    userOwner: null,
    userPatient: null,
    title: "",
    scheduling: TasksTaskSchedulingChoices.Due,
    state: TasksTaskStateChoices.New,
    dateEnd: undefined,
    dateStart: "",
    descriptionExternal: "",
    descriptionInternal: "",
    durationEstimate: null,
    durationCompleted: null,
    isRecurring: false,
    recurrence: 1,
    recurrenceInterval: TasksTaskRecurrenceIntervalChoices.TimesAYear,
    customer: CustomerEmpty,
    userCreatedBy: UserEmpty,
    taskTopics: [],
    documents: [],
    documentCategories: [],
    tasksChildren: [],
    createdAt: "",
};

export const TaskTopicEmpty: TaskTopicType = {
    id: ID_EMPTY,
    title: "",
    taskSet: [],
    createdAt: "",
};

export const ProfessionalGroupEmpty: ProfessionalGroupType = {
    id: ID_EMPTY,
    title: "",
    userSet: [],
    taskSet: [],
    createdAt: "",
};

export const SalesCatalogEmpty: SalesCatalogType = {
    id: ID_EMPTY,
    name: "",
    state: SalescatalogsSalesCatalogStateChoices.Draft,
    createdAt: "",
    salesPrices: [],
};

export const HealthcareTeamEmpty: HealthcareTeamType = {
    id: ID_EMPTY,
    company: CompanyEmpty,
    description: "",
    healthcareWorkers: [],
    taskSet: [],
    responsibleTeamCustomers: [],
    createdAt: "",
    createdBy: null,
    updatedAt: "",
    updatedBy: null,
};

export const ContactPersonEmpty: ContactPersonType = {
    id: ID_EMPTY,
    lastName: "",
    firstName: "",
    email: "",
    phoneNumber: "",
    contactType: OrganizationsContactPersonContactTypeChoices.Contract,
    customer: CustomerEmpty,
};

export const InsuranceCompanyEmpty: InsuranceCompanyType = {
    id: ID_EMPTY,
    name: "",
    businessId: "",
    phoneNumber: "",
    insuranceSet: [],
};

export const InsuranceEmpty: InsuranceType = {
    id: ID_EMPTY,
    insuranceCompany: InsuranceCompanyEmpty,
    insuranceType: InsurancesInsuranceInsuranceTypeChoices.Accident,
    insuranceNumber: "",
    description: "",
    instructions: "",
    url: "",
};

export const NodeEmpty: NodeType = {
    id: ID_EMPTY,
    shorthand: "",
    title: "",
    questionOverride: "",
    tag: "",
    question: null,
    link: null,
    groups: [],
    links: [],
    nextBranches: [],
};

export const QuestionEmpty: QuestionType = {
    id: ID_EMPTY,
    name: "",
    question: "",
    answerType: QuestionsQuestionAnswerTypeChoices.Text,
    textInfo: "",
    textHelp: "",
    key: null,
    mandatory: true,
    answerChoices: [],
    matrixQuestions: [],
    matrixQuestionFactors: [],
    settingsReady: false,
    answerScores: [],
    answerLimitMinimum: null,
    answerLimitMaximum: null,
    isSlider: false,
    sliderStep: 1.0,
    answerMultiplier: 1.0,
    nodes: [],
    createdAt: "",
};

export const NodeGroupEmpty: NodeGroupType = {
    id: ID_EMPTY,
    label: "",
    tag: "",
    nodes: [],
};

export const PartnerEmpty: PartnerType = {
    address: "",
    businessId: "",
    createdAt: "",
    email: "",
    partnerChildren: [],
    paymentCommitments: [],
    phone: "",
    procedurepartnerpriceSet: [],
    id: ID_EMPTY,
    name: "",
};

export const PaymentCommitmentEmpty: PaymentCommitmentType = {
    costLimit: "",
    invoicesForwardingToCustomer: false,
    kelaActionCategory:
        PaymentcommitmentsPaymentCommitmentKelaActionCategoryChoices.OtherHealthcare,
    paymentcommitmentSet: [],
    paymentcommitmentactionlogSet: [],
    procedures: [],
    extendedProcedures: [],
    paymentcommitmentprocedureSet: [],
    referenceNumber: "",
    user: UserEmpty,
    id: ID_EMPTY,
    partner: PartnerEmpty,
    reimbursementClass:
        PaymentcommitmentsPaymentCommitmentReimbursementClassChoices.KelaZero,
    createdAt: "",
    validUntil: "",
};

export const FitnessTestDeviceEmpty: FitnessTestDeviceType = {
    id: ID_EMPTY,
    name: "",
    deviceType: FitnesstestsFitnessTestDeviceDeviceTypeChoices.FitnessTest,
    isDefault: false,
    createdAt: "",
};
